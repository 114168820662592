import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LazyBackgroundImageLoader from 'components/Img/LazyBackgroundImageLoader';

const Firstchar = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: ${props => props.width}px;
  height: ${props => props.width}px;

  color: white;
  font-weight: 700;
  font-size: ${props => +props.width / 2}px;
  text-transform: capitalize;

  border-radius: 50%;
  border: 1px solid ${props => props.borderColor};

  background: ${props => props.url ? `url("${props.url}") center center / cover` : '#3ecf8e'};
  background-position: 50% 50%;
  background-repeat: no-repeat;
`;

const Avatar = props => {
  const {
    photo, name, borderColor, width
  } = props;
  const firstLetter = useMemo(() => (name ? name[0] : ''), [name]);
  const photoStyle = useMemo(
    () => css`
      position: relative;
      width: ${width}px;
      height: ${width}px;
      border-radius: 50%;
      border: 1px solid ${borderColor};
      flex-shrink: 0;
      overflow: hidden;
      background-position: 50% 50%!important;
      background-repeat: no-repeat!important;
      background-size: contain!important;
    `, []
  );

  return (
    <>
      {photo ? (
        <LazyBackgroundImageLoader src={photo} imgStyle={photoStyle} />
      ) : (
        <Firstchar width={width} borderColor={borderColor}>
          {firstLetter}
        </Firstchar>
      )}
    </>
  );
};

Avatar.propTypes = {
  photo: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.string,
  borderColor: PropTypes.string
};

Avatar.defaultProps = {
  photo: '',
  name: 'OWNRIDES',
  borderColor: '#26ae8b',
  width: '24'
};

export default Avatar;
