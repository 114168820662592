import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import raf from 'raf';
import Placeholder from './Placeholder';

const Photo = styled.div`
  ${props => props.overwritestyles}
`;

const NoLazyPhoto = styled(Photo)`
  ${props => props.src
    ? `background: url("${props.src}") center center / cover;`
    : ''}
`;

const LazyBackgroundImage = props => {
  const {
    src,
    overwritestyles,
    children,
    nolazy
  } = props;
  const photoRef = useRef();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const Img = new Image();
    Img.src = src;
    Img.onload = () => {
      raf(() => {
        if (!photoRef?.current) return;

        photoRef.current.style.background = `url("${src}") center center / cover`;
      });

      setLoaded(true);
    };
  }, [photoRef, src]);

  return nolazy ? (
    <NoLazyPhoto
      ref={photoRef}
      src={src}
      overwritestyles={overwritestyles}
    >
      {children}
    </NoLazyPhoto>
  ) : (
    <Photo
      ref={photoRef}
      overwritestyles={overwritestyles}
    >
      <Placeholder loaded={loaded} />
      {children}
    </Photo>
  );
};

LazyBackgroundImage.propTypes = {
  src: PropTypes.string,
  overwritestyles: PropTypes.arrayOf(PropTypes.any),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  nolazy: PropTypes.bool,
};

LazyBackgroundImage.defaultProps = {
  src: '',
  overwritestyles: '',
  children: null,
  nolazy: false,
};

export default LazyBackgroundImage;
