import React, {
  memo, useMemo, useState, useCallback
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  onErrorHandler,
  ErrorBoundaryFallbackComponent,
  withErrorBoundary
} from 'components/ErrorBoundry/ErrorBoundry';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  color: #343434;
`;

const Review = styled.span`
  margin: 0 0 0.5rem;
`;

const ExpandButton = styled.span`
  cursor: pointer;
  font-size: 1rem;
  color: #00b33b;
  display: block;
  text-transform: capitalize;
`;

const ExpandText = memo(props => {
  const {
    text: originalText,
    maxLength,
    className,
    showReadMore,
    t,
    eventTracking,
  } = props;
  const [show, setShow] = useState(false);

  const clickHandler = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      setShow(!show);
      eventTracking();
    },
    [show]
  );

  const text = useMemo(() => (isEmpty(originalText) ? '' : originalText),
    [originalText]);

  const [firstHalfText, secondHalfText] = useMemo(() => {
    if (text.length <= maxLength) {
      return [text, ''];
    }

    return [
      text.substring(0, maxLength),
      text.substring(maxLength, text.length)
    ];
  }, [text, maxLength]);

  if (!text) {
    return <Root {...props} className={className} />;
  }

  if (text.length <= maxLength) {
    return (
      <Root {...props} className={className}>
        <Review>{firstHalfText}</Review>
      </Root>
    );
  }

  if (!showReadMore) {
    return <Root {...props} className={className}>{`${firstHalfText}...`}</Root>;
  }

  return (
    <Root {...props} className={className}>
      <Review>
        {show ? `${firstHalfText}${secondHalfText}` : `${firstHalfText}...`}
      </Review>
      <ExpandButton onClick={clickHandler} onKeyDown={clickHandler}>
        {show ? 'Less' : t('READ_MORE')}
      </ExpandButton>
    </Root>
  );
});

ExpandText.propTypes = {
  text: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  showReadMore: PropTypes.bool,
  t: PropTypes.func,
  eventTracking: PropTypes.func,
};

ExpandText.defaultProps = {
  className: '',
  showReadMore: true,
  maxLength: 120,
  text: '',
  t: () => {},
  eventTracking: () => {},
};

export default withErrorBoundary(
  withTranslation(['common'])(ExpandText),
  ErrorBoundaryFallbackComponent,
  onErrorHandler
);
