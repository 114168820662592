import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import iconHalfStar from './assets/icon-halfstar.svg';
import iconStar from './assets/icon-star.svg';
import iconEmptyStar from './assets/icon-emptystar.svg';

const TOTAL_STARS = 5;
const Root = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #04844b;
  font-family: ${props => props.theme.fontSourceSansPro};

  & > img {
    :not(:last-of-type) {
      margin: 0;
    }
  }
`;
const Star = styled.img`
  margin: 0 0.25rem 0 0;
  padding: 1px 0;
  width: ${props => props.width}px;
`;

const NewRatingStar = ({ rating, totalReviews, width }) => {
  if (rating < 0) {
    return null;
  }

  const [solidStars, halfStar, emptyStar] = useMemo(() => {
    const halfStarCount = +(rating % 1 < 0.5 && rating % 1 !== 0);
    const fullStarCount = Math.round(rating);
    const emptyStarsCount = TOTAL_STARS - halfStarCount - fullStarCount || 0;

    const $solidStars = [...Array(fullStarCount)].map((i, index) => {
      const key = `star-${index}`;
      return <Star src={iconStar} alt={key} key={key} width={width} />;
    });

    const $halfStar = halfStarCount
      ? <Star src={iconHalfStar} alt="halfstar" key="halfstar" width={width} />
      : null;

    const $emptyStars = [...Array(emptyStarsCount)].map((i, index) => {
      const key = `emptystar-${index}`;
      return <Star src={iconEmptyStar} alt={key} key={key} width={width} />;
    });

    return [
      $solidStars,
      $halfStar,
      $emptyStars
    ];
  }, [rating]);

  return (
    <Root>
      {solidStars}
      {halfStar}
      {emptyStar}
      {totalReviews ? <span>{` (${totalReviews})`}</span> : null}
    </Root>
  );
};

NewRatingStar.propTypes = {
  rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalReviews: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

NewRatingStar.defaultProps = {
  rating: -1,
  totalReviews: 0,
  width: 12,
};

export default NewRatingStar;
