import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RatingStar from 'components/RatingStar/NewRatingStar';
import ExpandableTextPure from 'components/ExpandableTextPure/ExpandableTextPure';
import Avatar from 'components/Avatar/Avatar';
import { sendEvent } from 'utils/gtag';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */
const Root = styled.div`
  width: 278px;
  height: 100%;
  position: relative;
  font-family: ${props => props.theme.fontSourceSansPro};
  background: #FFFFFF;
  box-shadow: 0 3px 6px 1px rgba(0,0,0,0.10);
  border-radius: 4px;
  padding: 1rem;
  color: #343434;
`;

const ParagrahSmall = styled.span`
  line-height: 1.125rem;
  font-size: 0.75rem;
  color: #343434;
`;

const SeparationLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(180, 180, 180, 0.4);
  margin: 1rem 0;
`;

const DriverTagline = styled.h4`
  margin: 0 0 0.5rem;
  font-size: 1rem;
  font-weight: 300;
  font-style: oblique;
  color: #595959;
`;

const ReviewContent = styled(ExpandableTextPure)`
  margin: 0 0 1rem;
  white-space: normal;
`;

const DriverName = styled.h3`
  font-size: 1rem;
  margin: 0 0 0.5rem;
`;

const UserAvatar = styled.div`
  margin-right: 0.375rem;
`;

const Flex = styled.div`
  display: flex;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
`;

const Driver = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const LocalGuideReviewCard = ({
  driver,
  user,
  review,
  createAt,
}) => {
  const {
    photo: driverPhoto,
    name: driverName,
    tagline,
    rating,
    totalReviews
  } = driver;
  const {
    photo: userPhoto,
    name: userName
  } = user;

  return (
    <Root>
      <Driver>
        <FlexColumn>
          <ParagrahSmall>Driver</ParagrahSmall>
          <DriverName>{driverName}</DriverName>
          {
            tagline ? <DriverTagline>{ tagline }</DriverTagline> : null
          }
          <RatingStar rating={rating} totalReviews={totalReviews} />
        </FlexColumn>
        <Avatar
          width="80"
          photo={driverPhoto}
          borderColor="#26ae8b"
          name={driverName}
        />
      </Driver>
      <SeparationLine />
      <ReviewContent
        text={review}
        maxLength={120}
        eventTracking={() => {
          sendEvent({ action: 'Click Review Readmore Button', category: 'engagement' });
        }}
      />
      <User>
        <UserAvatar>
          <Avatar
            width="24"
            photo={userPhoto}
            borderColor="#b4b4b4"
            name={userName}
          />
        </UserAvatar>
        <ParagrahSmall>{userName}</ParagrahSmall>
        <ParagrahSmall>・</ParagrahSmall>
        <ParagrahSmall>{createAt}</ParagrahSmall>
      </User>
    </Root>
  );
};

LocalGuideReviewCard.propTypes = {
  driver: PropTypes.shape({
    photo: PropTypes.string,
    name: PropTypes.string,
    tagline: PropTypes.string,
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  user: PropTypes.shape({
    photo: PropTypes.string,
    name: PropTypes.string,
    tagline: PropTypes.string,
  }),
  createAt: PropTypes.string,
  review: PropTypes.string,
};

LocalGuideReviewCard.defaultProps = {
  driver: {
    photo: '',
    tagline: '',
    name: "OWNRIDES' Driver",
    rating: 4.5,
  },
  user: {
    photo: '',
    name: 'Anonymous',
  },
  createAt: '',
  review: ''
};

export default LocalGuideReviewCard;
