import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Root = styled.div`
  width: 100%;
  height: ${props => (props.height ? `${props.height}px` : '100%')};
  top: 0;
  left: 0;
  opacity: ${props => (props.loaded ? '0' : '1')};
  background-position: 50% 50%;
  background: #EFF0F1;
  background-size: 400% 400%;
`;

const Placeholder = memo(({ height, loaded }) => (
  <Root
    loaded={loaded}
    height={height}
    className="lazyload-placeholder"
  />
));

Placeholder.defaultProps = {
  height: '',
  loaded: true,
};

Placeholder.propTypes = {
  loaded: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Placeholder;
