import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LazyLoad, { forceCheck } from 'react-lazyload';
import LazyBackgroundImage from './LazyBackgroundImage';
// import Placeholder from './Placeholder';

const LazyBackgroundImageLoader = memo(({
  height,
  offset,
  src,
  imgStyle,
  children
}) => {
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    const _timer = setTimeout(() => {
      forceCheck();
    }, 1000);

    setTimer(_timer);

    return clearTimeout(timer);
  }, []);

  return (
    <>
      <LazyLoad
        offset={offset}
        height={height}
        once
      >
        <LazyBackgroundImage
          src={src}
          overwritestyles={imgStyle}
        >
          {children}
        </LazyBackgroundImage>
      </LazyLoad>
      <noscript>
        <style>{'.lazyload-placeholder { display: none; }'}</style>
        <LazyBackgroundImage
          src={src}
          overwritestyles={imgStyle}
          nolazy
        >
          {children}
        </LazyBackgroundImage>
      </noscript>
    </>
  );
});

LazyBackgroundImageLoader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offset: PropTypes.number,
  src: PropTypes.string,
  imgStyle: PropTypes.arrayOf(PropTypes.any),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};

LazyBackgroundImageLoader.defaultProps = {
  height: 80,
  offset: 0,
  src: '',
  imgStyle: [],
  children: null
};

export default LazyBackgroundImageLoader;
